<template>
    <div class="right_button_box">
        <div class="btns">
            <router-link tag="button" class="btn-1" :to="{path: '/recharge', query: {t: new Date().getTime()}}"> {{i18nConst.MENU['COININ'][$store.state.country]}}</router-link>
            <router-link tag="button" class="btn-1" :to="{path: '/exchange', query: {t: new Date().getTime()}}"> {{i18nConst.MENU['COINOUT'][$store.state.country]}}</router-link>
            <router-link tag="button" class="btn-1" :to="{path: '/customer_center', query: {t: new Date().getTime()}}">{{i18nConst.MENU['QUESTION'][$store.state.country]}}</router-link>
            <router-link tag="button" class="btn-1" :to="{path: '/notice', query: {t: new Date().getTime()}}">{{i18nConst.MENU['NOTICE'][$store.state.country]}}</router-link>
            <router-link tag="button" class="btn-1" :to="{path: '/sports_betinfo', query: {t: new Date().getTime()}}">{{i18nConst.MENU['BETLIST'][$store.state.country]}}</router-link>
            <router-link tag="button" class="btn-1" :to="{path: '/message', query: {t: new Date().getTime()}}">{{i18nConst.MENU['MESSAGE'][$store.state.country]}}</router-link>
<!--            <router-link tag="button" class="btn-1" :to="{path: '/culcheck', query: {t: new Date().getTime()}}">출석부</router-link>-->
            <router-link tag="button" class="btn-1" :to="{path: '/event', query: {t: new Date().getTime()}}">{{i18nConst.MENU['EVENT'][$store.state.country]}}</router-link>
            <router-link tag="button" class="btn-1" :to="{path: '/user_info', query: {t: new Date().getTime()}}">{{i18nConst.MENU['USERINFO'][$store.state.country]}}</router-link>

        </div>

    </div>
</template>

<script>
    import i18nConst from "../common/i18nConst";
    import sportsConst from "../common/sportsConst";

    export default {
        name: "RightButtonsComp",
        data() {
            return {
                i18nConst:i18nConst,
            }
        },

    }
</script>

<style scoped>
    .right_button_box{
        width: 100%;
        padding: 16px 5px;
        display: flex;
        justify-content: space-between;
    }
    .right_button_box .btns{
        width: 100%;
        display: flex;
        justify-content: space-between;
        --background-color: #f5f5f5;
        flex-wrap: wrap;
    }
    .right_button_box .btns .btn-1{
        width: 24.7%;
        height: 28px;
        background-color: #db800d;
        color: #ffffff;
        margin: 1px 0;
        font-weight: bold;
        border-radius: 3px;
    }
    @media screen and (max-width: 1024px) {
        .right_button_box{
            display: none!important;
        }
    }
</style>